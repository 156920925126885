<template>
  <v-dialog v-model="localDialog" max-width="500px">
    <v-card>
      <v-card-title>{{ $t('message.profileTitle') }}</v-card-title>
      <v-card-text>
        <div v-if="$auth.isAuthenticated">
          <v-img
            :src="modifiedProfilePicture"
            alt="User Profile"
            class="profile-img"
            contain
            width="100"
            height="100"
          />
          <h2>{{ $auth.user.name }}</h2>

          <!-- user_metadata の表示 -->
          <div v-if="userMetadata" class="mt-2">
            <v-row>
              <v-col cols="12" md="6">
                <h4>Twitter ID: {{ userMetadata.screen_name }}</h4>
              </v-col>
              <v-col cols="12" md="6" class="text-right">
                <v-btn color="primary" @click="redirectToUserPage">{{
                  $t('message.redirectButton')
                }}</v-btn>
              </v-col>
            </v-row>
          </div>

          <!-- ハウジングツイート投稿の説明 -->
          <h3 class="mt-4">{{ $t('message.postHousingTweet') }}</h3>

          <div>
            <v-text-field
              v-model="housingTweetURL"
              :label="$t('message.housingTweetUrlLabel')"
              prepend-icon="mdi-twitter"
              append-outer-icon="mdi-send"
              :hint="$t('message.housingTweetUrlHint')"
              @click:append-outer="submitHousingTweet"
            />
          </div>

          <!-- ハウジングツイート投稿削除の説明 -->
          <h3 class="mt-4">{{ $t('message.deleteHousingTweet') }}</h3>

          <div>
            <v-text-field
              v-model="deleteHousingTweetURL"
              :label="$t('message.housingTweetUrlLabel')"
              prepend-icon="mdi-twitter"
              append-outer-icon="mdi-send"
              :hint="$t('message.housingTweetUrlHint')"
              @click:append-outer="deleteHousingTweet"
            />
          </div>

          <!-- <div>
            <v-text-field
              v-model="furnitureTweetURL"
              label="組み合わせ家具ツイートURL"
              prepend-icon="mdi-twitter"
            />
            <v-btn color="primary" @click="submitFurnitureTweet">送信</v-btn>
          </div>

          <div>
            <v-text-field
              v-model="housingYouTubeURL"
              label="ハウジングYouTube動画URL"
              prepend-icon="mdi-youtube"
            />
            <v-btn color="primary" @click="submitHousingYouTube">送信</v-btn>
          </div> -->
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="$auth.isAuthenticated" color="primary" @click="logout">
          {{ $t('message.logoutButton') }}
        </v-btn>
        <v-btn color="primary" @click="localDialog = false">{{
          $t('message.close')
        }}</v-btn>
      </v-card-actions>
    </v-card>

    <!-- スナックバー -->
    <v-snackbar v-model="snackbarVisible" :timeout="3000">
      {{ snackbarMessage }}
    </v-snackbar>
  </v-dialog>
</template>

<script>
  import axios from 'axios';

  export default {
    name: 'ProfileDialog',
    props: {
      dialog: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        snackbarVisible: false, // スナックバーの表示状態
        snackbarMessage: '', // スナックバーに表示するメッセージ
        webhookUrl:
          'https://discord.com/api/webhooks/1283478586082459678/p5sXKY-9OuNF8nR4vJDPLhOyA84Eq5aRNxyNVGCOdSkIujd1SQ_F8G9SbwWdI7tGAn6w',
        userMetadata: null, // user_metadata の保存用
        twitterId: '', // Twitter ID の保存用
        housingTweetURL: '',
        deleteHousingTweetURL: '',
        furnitureTweetURL: '',
        housingYouTubeURL: '',
      };
    },
    computed: {
      // プロフィール画像のサイズを置き換えたものを返す
      modifiedProfilePicture() {
        // 現在のURLが `_normal.jpg` で終わっている場合に置き換え
        if (this.$auth.user && this.$auth.user.picture) {
          return this.$auth.user.picture.replace('_normal', '_400x400');
        }
        return ''; // 何かデフォルトの画像にフォールバック
      },
      localDialog: {
        get() {
          return this.dialog;
        },
        set(value) {
          this.$emit('update:dialog', value); // 親コンポーネントに変更を通知
        },
      },
    },
    watch: {
      localDialog(newVal) {
        if (newVal) {
          // ダイアログが開かれたら user_metadata を取得
          this.getUserMetadata();
        }
      },
    },
    methods: {
      showSnackbar(message) {
        this.snackbarMessage = message;
        this.snackbarVisible = true;
      },
      extractTwitterIdFromUrl(url) {
        // Twitter URLからユーザー名部分を抽出する正規表現
        const twitterUrlPattern =
          /https:\/\/(?:twitter\.com|x\.com)\/([^/]+)\/status\/\d+/;

        const match = url.match(twitterUrlPattern);

        // マッチする部分があれば、その部分を返す
        if (match && match[1]) {
          return match[1]; // URL内のTwitterユーザー名を返す
        } else {
          return null; // 見つからなければnullを返す
        }
      },
      checkIfTwitterIdsMatch(url) {
        // URLからTwitter IDを抽出
        const urlTwitterId = this.extractTwitterIdFromUrl(url);

        if (!urlTwitterId) {
          alert(this.$t('message.invalidTweetUrlAlert')); // 無効なURLの場合のエラーメッセージ
          return false;
        }

        // プロフィールから取得したTwitter ID（例: this.twitterId）と比較
        const profileTwitterId = this.twitterId;

        // 両方のIDを小文字に変換して比較
        if (urlTwitterId.toLowerCase() === profileTwitterId.toLowerCase()) {
          return true; // 一致した場合
        } else {
          alert(this.$t('message.twitterIdMismatchAlert')); // 一致しない場合のエラーメッセージ
          return false;
        }
      },
      async getUserMetadata() {
        try {
          // 認証されているか確認
          if (!this.$auth.isAuthenticated) {
            console.error('User is not authenticated');
            return;
          }

          const userId = this.$auth.user.sub; // "twitter|888306073"
          console.log(userId);

          console.log('this.$auth:' + this.$auth);

          const token = await this.$auth.getTokenSilently({});
          console.log(token);

          // トークンをデコードしてカスタムクレームからメタデータを取得
          const decodedToken = await this.$auth.getIdTokenClaims();

          // カスタムクレームからscreen_nameを取得
          const userMetadata =
            decodedToken['https://housingeden.user.metadata/user_metadata'];

          if (userMetadata) {
            // screen_nameをtwitterIdに設定
            this.twitterId = userMetadata.screen_name;
            console.log('Twitter ID set to:', this.twitterId);

            // 他のメタデータも取得（必要に応じて）
            this.userMetadata = userMetadata;
          } else {
            console.error('No user metadata found in token');
          }
        } catch (error) {
          console.error('Error fetching user_metadata:', error);
        }
      },
      async getUserIdByTwitterId(twitterId) {
        try {
          const response = await fetch(
            `../../../user/all_users_master.json?timestamp=${new Date().getTime()}`
          );
          const users = await response.json();

          const normalizedTwitterId = twitterId.toLowerCase();
          const user = users.find((user) => {
            return (
              Array.isArray(user.twitter_user_id) &&
              user.twitter_user_id.some(
                (id) => id.toLowerCase() === normalizedTwitterId
              )
            );
          });

          if (user) {
            return user.user_id;
          } else {
            console.log('User not found');
            return null; // エラーを投げずにnullを返す
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          return null;
        }
      },
      async redirectToUserPage() {
        const twitterId = this.twitterId; // ユーザーが入力した Twitter ID

        // user_id を取得
        const userId = await this.getUserIdByTwitterId(twitterId);

        if (userId) {
          // user_id を使って URL を生成してページに遷移
          const redirectUrl = `/components/artwork/${userId}/`;

          // 現在のパスと遷移先のパスが異なる場合のみ遷移
          this.localDialog = false;

          if (this.$route.path !== redirectUrl) {
            // ページ遷移
            this.$router.push(redirectUrl).catch((err) => {
              if (err.name !== 'NavigationDuplicated') {
                throw err;
              }
            });
          } else {
            console.log('Already on the desired page');
          }
        } else {
          alert(this.$t('message.userNotFoundAlert'));
        }
      },
      logout() {
        this.$auth.logout({
          logoutParams: {
            returnTo: window.location.origin,
            // federated: true,
          },
        });
      },
      saveTwitterId() {
        // Twitter ID をローカルストレージに保存
        localStorage.setItem('twitterId', this.twitterId);
      },
      isValidTweetUrl(url) {
        // 正規表現を使用してURLがTwitterのツイートURLかどうか確認
        const tweetUrlPattern =
          /https:\/\/(?:twitter\.com|x\.com)\/.+\/status\/(\d+)/;
        return tweetUrlPattern.test(url);
      },
      async submitHousingTweet() {
        // まずURLが有効なツイートURLかどうか確認
        if (!this.isValidTweetUrl(this.housingTweetURL)) {
          alert(this.$t('message.invalidTweetUrlAlert')); // URLが無効の場合のアラートメッセージ
          return;
        }
        // Twitter IDの一致を確認
        if (!this.checkIfTwitterIdsMatch(this.housingTweetURL)) {
          return; // 処理を中断
        }

        try {
          // ツイートURLが有効な場合にウェブフックに送信
          await this.sendToWebhook(this.housingTweetURL, 'postHousingTweet');
          // 成功時にスナックバーを表示
          this.showSnackbar(this.$t('message.postSuccessSnackbar'));
        } catch (error) {
          console.error('Error sending housing tweet:', error);
        }
      },
      async deleteHousingTweet() {
        // 削除のためのURLが有効か確認
        if (!this.isValidTweetUrl(this.deleteHousingTweetURL)) {
          alert(this.$t('message.invalidTweetUrlAlert'));
          return;
        }

        // Twitter IDの一致を確認
        if (!this.checkIfTwitterIdsMatch(this.deleteHousingTweetURL)) {
          return; // 処理を中断
        }

        try {
          await this.sendToWebhook(
            this.deleteHousingTweetURL,
            'deleteHousingTweet'
          );
          // 成功時にスナックバーを表示
          this.showSnackbar(this.$t('message.deleteSuccessSnackbar'));
        } catch (error) {
          console.error('Error sending delete housing tweet:', error);
        }
      },
      async submitFurnitureTweet() {
        try {
          await this.sendToWebhook(this.furnitureTweetURL, 'Furniture Tweet');
        } catch (error) {
          console.error('Error sending furniture tweet:', error);
        }
      },
      async submitHousingYouTube() {
        try {
          await this.sendToWebhook(this.housingYouTubeURL, 'Housing YouTube');
        } catch (error) {
          console.error('Error sending housing YouTube:', error);
        }
      },
      async sendToWebhook(url, messageType) {
        try {
          const userInfo = {
            name: this.$auth.user.name,
            picture: this.modifiedProfilePicture, // 修正済みのプロフィール画像URLを使用
            twitterId: this.twitterId,
          };

          // content に JSON 形式で messageType と user 情報を含める
          const payload = {
            content: JSON.stringify(
              {
                messageType: messageType,
                url: url,
                user: userInfo,
              },
              null,
              2
            ), // 2 はインデントスペース（見やすさのため）
          };
          // Webhook に POST する
          await axios.post(this.webhookUrl, payload);
        } catch (error) {
          console.error(`Error sending ${messageType}:`, error);
        }
      },
    },
  };
</script>

<style scoped>
  .rounded-btn {
    padding: 0;
    border-radius: 50%;
    width: 56px;
    height: 56px;
    min-width: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .profile-img {
    border-radius: 50%; /* 画像を丸くする */
    object-fit: cover; /* 画像をボタンサイズに合わせてカバー */
  }
</style>
